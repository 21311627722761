import { Avatar, Box, Button, Fab } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { API_BANNER_DELETE, API_BANNER_LIST } from '../../../Apis/banner_api';
import { API_URL } from '../../../Apis/config';
import DataGrideView from '../../../Component/DataGrideView/DataGrideView';
import AddIcon from "@mui/icons-material/Add";
import AddBanner from '../../../Component/Dailog/AddBanner';
import DeleteBannerDailog from '../../../Component/Dailog/DeleteBannerDailog';
import ToastView, { ToastShow } from '../../../Component/Toast/Toast';


function Banner() {

    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCall, setIsCall] = useState(true);
    const [open, setOpen] = useState(false);
    const [openD, setOpenD] = useState(false);
    const [selectedId, setSelectedId] = useState(0);

    const getData = async (v = 1) => {
        setIsLoading(true);

        const data = await API_BANNER_LIST(v ? rows.length : 0);

        if (v) {

            setRows([...rows, ...(data.r ?? [])]);
        } else {
            setRows([...(data.r ?? [])]);
        }
        setIsLoading(false);
        if ((data.r ?? []).length == 0) {
            setIsCall(false);
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClickOpenD = () => {
        setOpenD(true);
    }

    const handleOnClose = async (v) => {
        setOpen(false);
        if (v) {
            getData(0);
        }
    };

    const handleOnCloseD = async (v) => {
        setOpenD(false);
        if (v) {
            const d = await API_BANNER_DELETE({ id: selectedId });
            setSelectedId(0);
            if (d && d.s) {
                ToastShow("Deleted Successfully");
                getData(0);
            } else {
                ToastShow("Opps! something went wrong please try again.");
            }
        }
    };

    useEffect(() => {
        getData(0);
    }, []);

    const callBack = (l) => {
        if (l >= rows.length && isCall) {
            getData();
        }
    };

    const handelDelete = (id) => {
        setSelectedId(id);
        handleClickOpenD();
    }
    const columns = [
        {
            field: "id",
            headerName: "ID",
            headerAlign: "center",
            align: "center",
            width: 50,
        },
        {
            field: "photo",
            headerName: "Photo",
            headerAlign: "center",
            align: "center",
            width: 150,
            renderCell: (params) => {
                return <Avatar
                    sx={{
                        height: "100px",
                        width: "100px"
                    }}
                    src={`${API_URL.baseUrl}${API_URL.bannerProfile}${params.value}`} />
            }
        },
        {
            field: "thumb",
            headerName: "Thumb",
            headerAlign: "center",
            align: "center",
            width: 150,
            renderCell: (params) => {
                return <Avatar sx={{
                    height: "100px",
                    width: "100px"
                }} src={`${API_URL.baseUrl}${API_URL.bannerProfile}${params.value}`} />
            }
        },

        {
            field: "delete",
            headerName: "Action",
            headerAlign: "center",
            align: "center",
            width: 150,
            renderCell: (params) => {
                return <Button onClick={() => handelDelete(params.row.id)}>Delete</Button>
            }
        },

    ];


    return (
        <>
            <DataGrideView
                columns={columns}
                rows={rows}
                callBack={callBack}
                isLoading={isLoading}
            />
            <Fab
                onClick={handleClickOpen}
                sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2),
                }}
                color="primary"
                aria-label="add"
            >
                <AddIcon />
            </Fab>

            {
                open ? <AddBanner open={open} handleOnClose={handleOnClose} /> : null
            }
            {
                openD ? <DeleteBannerDailog open={openD} handleOnClose={handleOnCloseD} /> : null
            }

            <ToastView/>

        </>
    )
}

export default Banner
