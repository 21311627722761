import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import React from "react";

function StatusChangeDailog({ open, handleOnClose, status }) {
    return (
        <>
            <Dialog open={open} onClose={() => handleOnClose(0)}>
                <DialogTitle>{status == 1 ? "Activate" : (
                    status == 2 ? "Reject" : (
                        status == -1 ? "Blocked" : "Panding"
                    )
                )}</DialogTitle>
                <DialogContentText sx={{ width: "350px", mt: 1, px: 3 }}>
                    Are you sure, you want to {status == 1 ? "Activate" : (
                        status == 2 ? "Reject" : (
                            status == -1 ? "Blocked" : "Panding"
                        )
                    )} this user?
                </DialogContentText>
                <DialogActions>

                    <Button onClick={() => handleOnClose(1)} variant="text">
                        Yes
                    </Button>

                    <Button onClick={() => handleOnClose(0)} variant="text">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default StatusChangeDailog;
