import { API_URL, GETAPI, POSTAPI } from "./config";


export const API_BANNER_LIST = async (data) => {
  const res = await GETAPI(API_URL.bannerList, "&count=" + data);
  return res;
};

export const API_BANNER_ADD = async (data) => {
    const formData = new FormData();
    formData.append("photo",data.photo,"photo.png");
    formData.append("thumb",data.thumb,"thumb.png");
    const res = await POSTAPI(API_URL.bannerAdd, formData);
    return res;
  };

  export const API_BANNER_DELETE = async (data) => {
    const formData = new FormData();
    formData.append("id",data.id);
    const res = await POSTAPI(API_URL.bannerDelete,formData);
    return res;
  };


