import { API_URL, GETAPI, POSTAPI } from "./config";


export const API_GET_REDEEM = async () => {
  const res = await GETAPI(API_URL.redeemGet);
  return res;
};

export const API_UPDATE_REDEEM = async (data) => {
    const formData = new FormData();
    formData.append("redeem",data);
    const res = await POSTAPI(API_URL.redeemUpdate, formData);
    return res;
  };

  export const API_UPDATE_REDEEM_VALUE = async (data) => {
    const formData = new FormData();

    if(data.contactUs){
      formData.append("contact_us",data.contactUs);
    }

    if(data.minAmount){
      formData.append("min_amount",data.minAmount);
    }
    
    const res = await POSTAPI(API_URL.redeemUpdate, formData);
    return res;
  };



