import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import ItemView from "./ItemView";
import {useLocation} from 'react-router-dom'
import { useEffect } from "react";

function ExpandView({ item , openD}) {
  const location = useLocation();

  useEffect(()=>{
    console.log(location.pathname);
  },[])


  return (
    <Box>
      <Accordion
        defaultExpanded={location.pathname.includes(item.path)}
        sx={{
          width: "100%",
          backgroundColor: "secondary.main",
          mb: 1.8,
          mt: 1.8,
          border: "none",
          outline: "none",
        }}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={item.isExpand ? <ExpandMoreIcon /> : null}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            {item.icon}
           {openD && <Typography sx={{ width: "100%", ml: 1 }}>{item.title}</Typography> } 
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {item.children.map((v) => {
            return v.isExpand ? (
              <ExpandView item={v} key={v.id} width={"200px"} openD={openD}/>
            ) : (
              <ItemView item={v} key={v.id} width={"200px"} bgColor={"secondary.dark"} openD={openD} />
            );
          })}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default ExpandView;
