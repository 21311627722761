import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Apis/config";
import { API_CHANGE_STATUS, API_GET_LIST } from "../../../Apis/user_api";
import SplitButton from "../../../Component/Button/SplitButton";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { ToastShow } from "../../../Component/Toast/Toast";

function UserList() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);

  const getData = async (v = 1) => {
    setIsLoading(true);

    const data = await API_GET_LIST(v ? rows.length : 0);

    if (v) {

      setRows([...rows, ...(data.r ?? [])]);
    } else {
      setRows([...(data.r ?? [])]);
    }
    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData();
    }
  };

  const onSubmit = async (id, status) => {

    const res = await API_CHANGE_STATUS(id, status);
    if (res && res.s) {
      ToastShow("Success");
      getData(0);
    } else {
      ToastShow("Opps! something went wrong. Please try again.");
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      width: 50,
    },
    {
      field: "profile",
      headerAlign: "center",
      align: "center",
      headerName: "Profile",
      width: 70,

      renderCell: (params) => {
        return (
          <Avatar
            src={`${API_URL.baseUrl + API_URL.userProfile + params.value}`}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 150,
      type: "string",
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      width: 230,
      type: "string",
    },
    {
      field: "m_no",
      headerName: "Mobile No.",
      headerAlign: "center",
      align: "center",
      width: 130,
      type: "string",
    },
    {
      field: "address",
      headerName: "Address",
      headerAlign: "center",
      align: "center",
      width: 250,
      type: "string",
    },
    {
      field: "pan_no",
      headerName: "Pan no.",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "delear_name",
      headerName: "Dealer Name",
      headerAlign: "center",
      align: "center",
      width: 180,
      type: "string",
    },
    {
      field: "delear_mo_no",
      headerName: "Dealer Mo.No.",
      headerAlign: "center",
      align: "center",
      width: 130,
      type: "string",
    },
    {
      field: "t_point",
      headerName: "Total Point",
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "upi_id",
      headerAlign: "center",
      align: "center",
      headerName: "UPI-ID",
      width: 190,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.upi_id}</Typography>;
      },
    },
    {
      field: "bank_name",
      headerAlign: "center",
      align: "center",
      headerName: "Bank Name",
      width: 190,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.bank_name}</Typography>;
      },
    },
    {
      field: "ifsc",
      headerAlign: "center",
      align: "center",
      headerName: "IFSC",
      width: 150,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.ifsc}</Typography>;
      },
    },
    {
      field: "ac",
      headerAlign: "center",
      align: "center",
      headerName: "AC.NO.",
      width: 190,
      renderCell: (params) => {
        return (
          <Box>
            <Typography>{params.row.bank_details.ac_num}</Typography>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerAlign: "center",
      align: "center",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        return (
          <Typography
          sx={{
            color:getColor(params.value)
          }}
          >
            {params.value === 0
              ? "Requested"
              : params.value === 1
                ? "Activated"
                : params.value === 2
                  ? "Rejected"
                  : "Blocked"}
          </Typography>
        );
      },
    },
    {
      field: "action",
      headerAlign: "center",
      align: "center",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return <SplitButton
          dataId={params.row.id}
          onSubmit={onSubmit}
          isActivate={params.row.status == 1 ? null : 1}
          isBlocked={params.row.status == 1 ? 1 : null}
          isReject={params.row.status == 0 ? 1 : null}
        />
      }
    }
  ];

  const getColor = (value)=>{

    if(value == 0){
      return "#FFA500";
    }else if(value == 1){
      return "#FFA500";
    }else if(value == 2){
      return "#808080";
    }else {
      return "#800000";
    }
  }

  return (
    <DataGrideView
      columns={columns}
      rows={rows}
      callBack={callBack}
      isLoading={isLoading}
    />
  );
}

export default UserList;
