import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import React from "react";

function AskToGenerateCode({ open, handleOnClose }) {
    return (
        <>
            <Dialog open={open} onClose={() => handleOnClose(0)}>
                <DialogTitle>Generate Qr Code</DialogTitle>
                <DialogContentText sx={{ width: "350px", mt: 1, px: 3 }}>
                    Are you sure, you want to download new QR code?
                </DialogContentText>

                <DialogActions>

                    <Button onClick={() => handleOnClose(1)} variant="text">
                        Yes
                    </Button>

                    <Button onClick={() => handleOnClose(0)} variant="text">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AskToGenerateCode;
