import { API_URL, GETAPI, POSTAPI } from "./config";



export const API_SEND_NOTIFICATION = async (title,body) => {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("body", body );
  const res = await POSTAPI(API_URL.sendNotification, formData);
  return res;
};


