import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

function ItemView({
  item,
  openD,
  changeParent = true,
  bgColor = "secondary.main",
}) {
  const location = useLocation();
  const navigate = useNavigate();

  let addMore = {
    p: 1.4,
    pl: 1.9,
  };

  if (!openD && changeParent == true) {
    addMore = {
      height: "30px",
      width: "25px",
      p: 0,
      ...(location.pathname == item.path && {
        justifyContent: "center",
        width: "40px",
      }),
    };
  }

  return (
    <div
      style={{
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(item.path);
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          backgroundColor: bgColor,

          mb: 1.8,
          border: "none",
          outline: "none",
          borderRadius: "3px",
          ...addMore,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
          }}
        >
          {item.icon}
          {openD && (
            <Typography sx={{ width: "100%", ml: 1 }}>{item.title}</Typography>
          )}
        </Box>
        {location.pathname == item.path && (
          <Box
            sx={{
              borderRadius: "50%",
              width: "8px",
              height: "8px",
              backgroundColor: "primary.main",
            }}
          />
        )}
      </Box>
    </div>
  );
}

export default ItemView;
