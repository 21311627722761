import axios from "axios";

export const API_URL = {
    baseUrl: "https://api.silguard.in/",
    login: "api/auth/login",
    userList: "api/user/get-list",
    userRequest: "api/user/get-requested-list",
    userUpdate: "api/user/update",
    barcodeRequest: "api/barcode/get-request",
    generateCode: "api/barcode/generate-code",
    generatedCodeList: "api/barcode/barcode-get-list",
    barcodePaidRequest: "api/barcode/paid-request",
    barcodeComplatedRequest: "api/barcode/completed-request-list",
    barcodeLastCode: "api/barcode/last-code",
    barcodeAnalitics: "api/barcode/analitics",
    bannerList: "api/banner/get-list",
    bannerAdd: "api/banner/add",
    bannerDelete: "api/banner/delete",
    topRanker: "api/barcode/get-ranker",
    userProfile:"upload/profile/",
    bannerProfile:"upload/banner/",

    redeemUpdate: "api/user/update-redeem",
    redeemGet: "api/user/get-redeem",

    sendNotification : "api/notification/send"

};

export const API = axios.create({ baseURL: API_URL.baseUrl });

export async function GETAPI(url, data = "") {
    try {
        const auths = `?apikey=${tokens.apikey}&token=${tokens.token}`;
        const result = await API.get(url + auths + data, {
            headers: null,
        });
        console.log(result.data);
        return result.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function POSTAPI(url, data) {
    try {
        data.append("apikey", tokens.apikey);
        data.append("token", tokens.token);
        const result = await axios.post(API_URL.baseUrl + url, data, {
            headers: null,
        });
        console.log(result.data);
        return result.data;
    } catch (e) {
        console.log(e);
        return null;
    }
}

const tokens = {};
export default tokens;
