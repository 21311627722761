import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Apis/config";
import { API_QR_RANKER } from "../../../Apis/qr_code_api";

import DataGrideView from "../../../Component/DataGrideView/DataGrideView";


function TopRanker() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);

  const getData = async (v = 1) => {
    setIsLoading(true);

    const data = await API_QR_RANKER(v ? rows.length : 0);

    if (v) {

      setRows([...rows, ...(data.r ?? [])]);
    } else {
      setRows([...(data.r ?? [])]);
    }
    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  useEffect(() => {
    getData(0);
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData();
    }
  };


  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      width: 50,
    },
    {
      field: "profile",
      headerAlign: "center",
      align: "center",
      headerName: "Profile",
      width: 70,

      renderCell: (params) => {
        return (
          <Avatar
            src={`${API_URL.baseUrl + API_URL.userProfile + params.value}`}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 150,
      type: "string",
    },

    {
      field: "m_no",
      headerName: "Mobile No.",
      headerAlign: "center",
      align: "center",
      width: 130,
      type: "string",
    },

    {
      field: "t_point",
      headerName: "Total Point",
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    

  ];



  return (
    <DataGrideView
      columns={columns}
      rows={rows}
      callBack={callBack}
      isLoading={isLoading}
    />
  );
}

export default TopRanker;
