import { Box, Button, Divider, Switch, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { API_GET_REDEEM, API_UPDATE_REDEEM, API_UPDATE_REDEEM_VALUE } from '../../../Apis/redeem_api';
import ToastView, { ToastShow } from '../../../Component/Toast/Toast';

function Redeem() {

    const [value, setValue] = useState(false);
    const [miniNumber, setMiniNumber] = useState("");
    const [contactUs, setContactUs] = useState("");
    const getRedeem = async () => {

        const data = await API_GET_REDEEM();

        if (data && data.s) {
            // console.log("call");
            setValue(data.r.is_redeem ? true : false);
            setMiniNumber(data.r.min_amount);
            setContactUs(data.r.contact_us);
        }
    }

    useEffect(() => {
        getRedeem();
    }, []);

    const update = async () => {
        await API_UPDATE_REDEEM_VALUE({
            contactUs: contactUs,
            minAmount: miniNumber,
        });

        ToastShow("Success");
    }


    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="start"
            p={5}
        >
            <Typography variant='subtitle2' sx={{ fontSize: "20px" }}>On/Off the redeem button for the app.</Typography>
            <Divider />
            <Typography sx={{ mt: 3, fontSize: "22px" }} variant='subtitle1'>Redeem</Typography>
            <Box
                display="flex"
                alignItems="center"
                border="solid"
                color="divider"
                p={2}
            >
                <Typography>Disable</Typography>
                <Switch checked={value} onChange={async (e) => {
                    // console.log(e.target.checked);
                    setValue(e.target.checked);
                    await API_UPDATE_REDEEM(e.target.checked ? 1 : 0);
                }} />
                <Typography>Enable</Typography>

            </Box>

            <Divider />
            <Box
                display="flex"
                alignItems="center"
                mt={2}
            >
                <TextField
                    onChange={(e) => setMiniNumber(e.target.value)}
                    value={miniNumber} label="Minimum amount" type="number" sx={{ width: "250px" }} />
                <Button sx={{ ml: 2 }} onClick={() => update()} variant='outlined'>Update</Button>
            </Box>

            <Box
                display="flex"
                alignItems="center"
                mt={2}
            >
                <TextField value={contactUs}
                    onChange={(e) => setContactUs(e.target.value)}
                    label="Contact number" type="number" sx={{ width: "250px" }} />
                <Button sx={{ ml: 2 }} onClick={() => update()} variant='outlined'>Update</Button>
            </Box>

            <ToastView/>
        </Box>
    )
}

export default Redeem
