import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Apis/config";
import { API_CHANGE_STATUS, API_USER_REQUEST } from "../../../Apis/user_api";
import StatusChangeDailog from "../../../Component/Dailog/StatusChangeDailog";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import { ToastShow } from "../../../Component/Toast/Toast";

function UserRequest() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);
  const [selectedId, setSelectedId] = useState(0);
  const [status, setStatus] = useState(0);
  const [open, setOpen] = useState(false);


  const getData = async (v = 1) => {
    setIsLoading(true);

    const data = await API_USER_REQUEST(v ? rows.length : 0);

    if (v) {

      setRows([...rows, ...(data.r ?? [])]);
    } else {
      setRows([...(data.r ?? [])]);
    }
    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  const openDialog = (status, id) => {
    setStatus(status);
    setSelectedId(id);
    setOpen(true);
  };

  const handleOnClose = async (v) => {
    if (selectedId && v) {
      const res = await API_CHANGE_STATUS(selectedId, status);
      if (res && res.s) {
        setSelectedId(0);
        setStatus(0);
        ToastShow("Success");
        getData(0);
      } else {
        ToastShow("Opps! something went wrong. Please try again.");
      }
    }
    setOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData();
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      width: 50,
    },
    {
      field: "profile",
      headerAlign: "center",
      align: "center",
      headerName: "Profile",
      width: 70,

      renderCell: (params) => {
        return (
          <Avatar
            src={`${API_URL.baseUrl + API_URL.userProfile + params.value}`}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 150,
      type: "string",
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      align: "center",
      width: 230,
      type: "string",
    },
    {
      field: "m_no",
      headerName: "Mobile No.",
      headerAlign: "center",
      align: "center",
      width: 130,
      type: "string",
    },
    {
      field: "address",
      headerName: "Address",
      headerAlign: "center",
      align: "center",
      width: 250,
      type: "string",
    },
    {
      field: "pan_no",
      headerName: "Pan no.",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "delear_name",
      headerName: "Dealer Name",
      headerAlign: "center",
      align: "center",
      width: 180,
      type: "string",
    },
    {
      field: "delear_mo_no",
      headerName: "Dealer Mo.No.",
      headerAlign: "center",
      align: "center",
      width: 130,
      type: "string",
    },
    {
      field: "t_point",
      headerName: "Total Point",
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "upi_id",
      headerAlign: "center",
      align: "center",
      headerName: "UPI-ID",
      width: 190,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.upi_id}</Typography>;
      },
    },
    {
      field: "bank_name",
      headerAlign: "center",
      align: "center",
      headerName: "Bank Name",
      width: 190,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.bank_name}</Typography>;
      },
    },
    {
      field: "ifsc",
      headerAlign: "center",
      align: "center",
      headerName: "IFSC",
      width: 150,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.ifsc}</Typography>;
      },
    },
    {
      field: "ac",
      headerAlign: "center",
      align: "center",
      headerName: "AC.NO.",
      width: 190,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.ac_num}</Typography>;
      },
    },
    {
      field: "status",
      headerAlign: "center",
      align: "center",
      headerName: "Action",
      width: 220,
      renderCell: (params) => {
        return (
          <Box display="flex">
            <Button variant="outlined" onClick={() => openDialog(1, params.row.id)} sx={{ mr: .5 }}>Activate</Button>
            <Button variant="outlined" onClick={() => openDialog(2, params.row.id)}>Reject</Button>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <DataGrideView
        columns={columns}
        rows={rows}
        callBack={callBack}
        isLoading={isLoading}
      />
      {
        open ? <StatusChangeDailog open={open} handleOnClose={handleOnClose} status={status} /> : null
      }
    </>
  );
}

export default UserRequest;
