import { API_URL, GETAPI, POSTAPI } from "./config";


export const API_QR_REQUEST = async (data) => {
  const res = await GETAPI(API_URL.barcodeRequest, "&count=" + data);
  return res;
};

export const API_QR_RANKER = async (data) => {
  const res = await GETAPI(API_URL.topRanker, "&count=" + data);
  return res;
};

export const API_ANALITICS= async (data) => {
  const res = await GETAPI(API_URL.barcodeAnalitics);
  return res;
};

export const API_QR_GENERATED_LIST = async (data) => {
  const res = await GETAPI(API_URL.generatedCodeList, "&count=" + data);
  return res;
};

export const API_QR_REQUEST_COMPLATED = async (data) => {
  const res = await GETAPI(API_URL.barcodeComplatedRequest, "&count=" + data);
  return res;
};

export const GENERATE_QR_CODE = async (data) => {
  const query = "&codeNumber="+data.codeNumber+"&year="+data.year+"&ltr="+data.ltr+"&code="+data.code+"&point="+data.point;
  const res = await GETAPI(API_URL.generateCode,query);
  return res;
};

export const API_PAID_REQUEST = async (id,amount,dateTime,documentation) => {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("amount", amount ?? 0);
  formData.append("paid_date_time", dateTime);
  formData.append("paid_document_id", documentation);
  const res = await POSTAPI(API_URL.barcodePaidRequest, formData);
  return res;
};

export const API_QR_LAST_CODE = async (data) => {
  const res = await GETAPI(API_URL.barcodeLastCode,);
  return res;
};

