import { Avatar, Box, Button, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Apis/config";
import { API_PAID_REQUEST, API_QR_REQUEST } from "../../../Apis/qr_code_api";
import ConfirmationDailog from "../../../Component/Dailog/ConfirmationDailog";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";
import ToastView, { ToastShow } from "../../../Component/Toast/Toast";

function QrCodeRequest() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [amount, setAmount] = useState("");
  const [documentaion, setDocumentaion] = useState("");
  const [paidDateTime, setPaidDateTime] = useState(new Date());

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  }

  const handleChangeDocumentaion = (e) => {
    setDocumentaion(e.target.value);
  }

  const handleChangePaidDateTime = (e) => {
    // console.log("date-time",e)
    setPaidDateTime(e);
  }

  const getData = async (v = 1) => {
    setIsLoading(true);

    const data = await API_QR_REQUEST(v ? rows.length : 0);

    if (data && data.s) {
      if (v) {
        setRows([...rows, ...(data.r ?? [])]);
      } else {
        setRows([...(data.r ?? [])]);
      }

    }

    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData();
    }
  };

  const openDialog = (value) => {
    setAmount("");
    setSelectedId(value.id);
    setOpen(true);
  };

  const handleOnClose = async (v) => {
    if (selectedId && v) {
      if (!amount) {
        ToastShow("Please fill amount");
      }
      else if (!documentaion) {
        ToastShow("Please fill payment reference id");
      }
      else if (!paidDateTime) {
        ToastShow("Please fill amount paid date-time");
      }

      else {

        const res = await API_PAID_REQUEST(
          selectedId,
          amount, 
          format(paidDateTime, "yyyy-MM-dd hh:mm:ss"),
          documentaion);
        if (res && res.s) {
          setSelectedId(0);
          setOpen(false);
          setAmount("");
          setDocumentaion("");
          setPaidDateTime(new Date());
          ToastShow("Successfully paid");
          getData(0);
        } else {
          ToastShow("Opps! something went wrong. Please try again.");
        }
      }

    } else {
      setOpen(false);
    }

  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      width: 50,
    },
    {
      field: "profile",
      headerAlign: "center",
      align: "center",
      headerName: "Profile",
      width: 70,

      renderCell: (params) => {
        return (
          <Avatar
            src={`${API_URL.baseUrl + API_URL.userProfile + params.value}`}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 150,
      type: "string",
    },
    {
      field: "m_no",
      headerName: "Mobile No.",
      headerAlign: "center",
      align: "center",
      width: 130,
      type: "string",
    },

    {
      field: "t_point",
      headerName: "Total Point",
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params) => {
        return <Typography>{params.row.points.t_point}</Typography>;
      },
    },
    {
      field: "pan_no",
      headerName: "Pan no.",
      headerAlign: "center",
      align: "center",
      width: 180,
    },
    {
      field: "upi_id",
      headerAlign: "center",
      align: "center",
      headerName: "UPI-ID",
      width: 190,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.upi_id}</Typography>;
      },
    },
    {
      field: "bank_name",
      headerAlign: "center",
      align: "center",
      headerName: "Bank Name",
      width: 190,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.bank_name}</Typography>;
      },
    },
    {
      field: "ifsc",
      headerAlign: "center",
      align: "center",
      headerName: "IFSC",
      width: 150,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.ifsc}</Typography>;
      },
    },
    {
      field: "ac",
      headerAlign: "center",
      align: "center",
      headerName: "AC.NO.",
      width: 190,
      renderCell: (params) => {
        return (
          <Box>
            <Typography>{params.row.bank_details.ac_num}</Typography>
          </Box>
        );
      },
    },
    {
      field: "req_type",
      headerAlign: "center",
      align: "center",
      headerName: "Req. Type",
      width: 190,
      renderCell: (params) => {
        return (
          <Box>
            <Typography>{params.value == 1 ? "Money" : "Reward"}</Typography>
          </Box>
        );
      },
    },
    {
      field: "action",
      headerAlign: "center",
      align: "center",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <Button variant="outlined" onClick={() => openDialog(params.row)}>
            Pay
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <DataGrideView
        columns={columns}
        rows={rows}
        callBack={callBack}
        isLoading={isLoading}
      />
      {open ? (
        <ConfirmationDailog
          handleOnClose={handleOnClose}
          handleChangeAmount={handleChangeAmount}
          amount={amount}
          handleChangeDocumentaion={handleChangeDocumentaion}
          documentaion={documentaion}
          handleChangePaidDateTime={handleChangePaidDateTime}
          paidDateTime={paidDateTime}
          open={open}
        />
      ) : null}
      <ToastView />
    </>
  );
}

export default QrCodeRequest;
