import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CollectionsIcon from '@mui/icons-material/Collections';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { NotificationAdd, Settings } from "@mui/icons-material";

export const MenuItems = [
  {
    id: "1",
    path: "/dashboard",
    isExpand: false,
    icon: <DashboardCustomizeIcon color="#ebe1e1" />,
    title: "Dashboard",
  },
  {
    id: "2",
    path: "/user-list",
    isExpand: false,
    icon: <GroupIcon color="#ebe1e1" />,
    title: "User List",
  },
  {
    id: "3",
    path: "/user-request",
    isExpand: false,
    icon: <GroupAddIcon color="#ebe1e1" />,
    title: "User Request",
  },
  {
    id: "4",
    path: "/requested-qr-code",
    isExpand: false,
    icon: <CoPresentIcon color="#ebe1e1" />,
    title: "Req Qr Code",
  },
  {
    id: "5",
    path: "/completed-qr-request",
    isExpand: false,
    icon: <DoneAllIcon color="#ebe1e1" />,
    title: "Com. Request",
  },
  {
    id: "6",
    path: "/qr-code",
    isExpand: false,
    icon: <QrCode2Icon color="#ebe1e1" />,
    title: "Qr Code",
  },
  {
    id: "7",
    path: "/generate-qr-code",
    isExpand: false,
    icon: <QrCode2Icon color="#ebe1e1" />,
    title: "Generate Qr Code",
  },
   {
    id: "8",
    path: "/banner",
    isExpand: false,
    icon: <CollectionsIcon color="#ebe1e1" />,
    title: "Banner",
  },
  {
    id: "9",
    path: "/top-ranker",
    isExpand: false,
    icon: <MilitaryTechIcon color="#ebe1e1" />,
    title: "Top Ranker",
  },
  {
    id: "10",
    path: "/config",
    isExpand: false,
    icon: <Settings color="#ebe1e1" />,
    title: "Config",
  },
  {
    id: "11",
    path: "/notification",
    isExpand: false,
    icon: <NotificationAdd color="#ebe1e1" />,
    title: "Notification",
  },
];
