import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";

function ConfirmationDailog({ 
  open, 
  handleOnClose, 
  handleChangeAmount, 
  amount, paidDateTime,
   handleChangePaidDateTime,
   handleChangeDocumentaion,
   documentaion }) {
  return (
    <>
      <Dialog open={open} onClose={() => handleOnClose(0)}>
        <DialogTitle>Finish request</DialogTitle>
        <DialogContentText sx={{ width: "350px", mt: 1, px: 3 }}>
          Are you sure, you want to close this request? if yes then please fill
          amount.
        </DialogContentText>
        <DialogContent>
          <TextField label="amount" value={amount} onChange={handleChangeAmount} type="number" sx={{ width: "350px" }} />
          <TextField 
          
          label="Payment reference id" 
          value={documentaion} 
          onChange={handleChangeDocumentaion} 
         
          sx={{ width: "350px",my:2 }} />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Paid Date-Time" value={paidDateTime}
              onChange={handleChangePaidDateTime}
              renderInput={(params) =>
                <TextField sx={{width: "350px"}} {...params} />} />
          </LocalizationProvider>

          {/* <TextFieldView
              value={name}
              onchange={handleName}
              label="City name"
              type="text"
              sx={{ width: "100%" }}
            /> */}
        </DialogContent>

        <DialogActions>

          <Button onClick={() => handleOnClose(1)} variant="text">
            Submit
          </Button>

          <Button onClick={() => handleOnClose(0)} variant="text">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmationDailog;
