import { Box, Button, CircularProgress, Divider, TextField, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { API_QR_LAST_CODE, GENERATE_QR_CODE } from '../../../Apis/qr_code_api';
import GenerateCodeDailog from '../../../Component/Dailog/GenerateQrCodeConfirmation';
import ToastView, { ToastShow } from '../../../Component/Toast/Toast';
import QRCode from "qrcode.react";
function GenerateQrCode() {


    const [point, setPoint] = useState(50);
    const [ltr, setLtr] = useState(450);
    const [codeNumber, setCodeNumber] = useState(20);
    // const [lastId, setId] = useState(1);
    // const [year, setYear] = useState(0);
    // const [code, setCode] = useState("An");
    // const [lastNumber, setLastNumber] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [row, setRow] = useState([]);
    const [open, setOpen] = useState(false);

    // const get_data = async () => {
    //     const data = await API_QR_LAST_CODE();

    //     if (data && data.s) {
    //         if (data.r) {
    //             setRow(data.r);
    //             const v = data.r.id + 1
    //             setId(v);
    //             const t = v + +codeNumber - 1;
    //             setLastNumber(t);
    //         }
    //     }

    // }

    // useEffect(() => {
    //     const d = new Date();
    //     setYear(d.getFullYear());
    //     // get_data();
    // }, []);

    useEffect(() => {

        if (row.length) {
            setTimeout(() => downloadQR(), 500);

        }

    }, [row]);

    const downloadQR = async () => {
        var count = 0;

        for (const v of row) {
            downloadQRCode(v.code, v.id,v.ltr,v.point);
            if (++count >= 10) {
                await pause(1000);
                count = 0;
            }
        }
        setRow([]);
    }

    function pause(msec) {
        return new Promise(
            (resolve, reject) => {
                setTimeout(resolve, msec || 1000);
            }
        );
    }

    const downloadQRCode = (code, id,ltr,point) => {
        // Generate download with use canvas and stream
        const canvas = document.getElementById(code);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `SG_${ltr}@${point}-${id}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleOnClose = async (v) => {
        if (v) {
            handleSubmit();
        }
        setOpen(false);
    };

    const openDialog = () => {
        setOpen(true);
    };

    const handleSubmit = async () => {

        const d = new Date();
        const y = d.getFullYear();


        if (!point) {
            ToastShow("Please fill point");
        }
        else if (!ltr) {
            ToastShow("Please fill ltr");
        }
        else if (!codeNumber) {
            ToastShow("Please fill Qr code generate number");
        }
        else if (+codeNumber < 1 || +codeNumber > 50) {
            ToastShow("Only 1 to 50 allow Qr code generate number");
        }
        else {
            setIsLoading(true);
            const d = await GENERATE_QR_CODE({
                year: y,
                ltr: ltr,
                codeNumber: codeNumber - 1,
                point: point
            });

            if (d && d.s) {
                ToastShow("QR code generated successfully");
                setRow(d.r);
                // get_data();
            } else {
                ToastShow("Something went wrong. Please try again.");
            }
            setIsLoading(false);
        }

    }


    return (
        <Box
            sx={{
                p: 5
            }}>

            {/* <Typography>Your QR series number start from - 000{lastId}</Typography> */}

            {/* <Box
                sx={{
                    mt: 3,
                    alignItems: "center",
                    display: "flex"
                }}
            >
                <Typography >Your  QR series code start from :- </Typography>
                <Typography variant='subtitle2'
                    sx={{ ml: 1, color: "primary.main" }}
                >SG/{year}/@#$/{code}/000{lastId}</Typography>
                <Typography
                    variant='subtitle2'
                    sx={{ mx: 2 }}
                > TO </Typography>
                <Typography
                    variant='subtitle2'
                    sx={{ color: "primary.main" }}
                > SG/{year}/@#$/{code}/000{lastNumber}</Typography>
            </Box> */}

            {/* <Divider sx={{ mb: 2, mt: 1 }} /> */}
            <Typography sx={{ mt: 4, mb: 1, color: "primary.main" }}>
                *) Create Your QR Code
            </Typography>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>
                =: Please modify QR code details. Below details just for example to easy understand.
            </Typography>
            <Typography variant='subtitle2' sx={{ mb: 3 }}>
                =: For your convenient after generate Qr code value will not be reset.
            </Typography>

            {/* <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 1
                }}
            >
                <Typography>
                    SG/
                </Typography>
                <TextField
                    label="Year"
                    value={year}
                    type="number"
                    onChange={(e) => setYear(e.target.value)}
                    sx={{ width: "100px", mx: 2 }}
                    size="small" />
                <Typography>
                    /@#$/
                </Typography>
                <TextField
                    label="Code"
                    value={code}
                    type="text"
                    onChange={(e) => setCode(e.target.value)}
                    sx={{ width: "60px", mx: 2 }}
                    size="small" />
                <Typography>
                    /000{lastId}
                </Typography>
            </Box> */}


            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    mt: 3
                }}
            >
                <TextField
                    label="Points"
                    value={point}
                    type="number"
                    sx={{ width: "100px", mr: 2 }}

                    onChange={(e) => setPoint(e.target.value)}
                    size="small" />

                <TextField
                    label="LTR"
                    value={ltr}
                    type="number"

                    onChange={(e) => setLtr(e.target.value)}
                    sx={{ width: "100px", mx: 2 }}
                    size="small" />

                <TextField
                    label="How many QR code you want to generate"
                    value={codeNumber}
                    type="number"
                    onChange={(e) => setCodeNumber(e.target.value)}

                    sx={{ width: "250px", mx: 2 }}
                    size="small" />
                <Typography variant='subtitle1'>
                    (Limit 1 to 50)
                </Typography>

            </Box>

            {
                isLoading ?
                    <CircularProgress />
                    :
                    <Button onClick={openDialog} variant='outlined' sx={{ mt: 5, mb: 2 }}>
                        Generate
                    </Button>}
            <ToastView />
            <Divider />
            {/* {
                row && <Typography sx={{ mt: 3 }}>Last Generated Qr code {row.code}</Typography>
            } */}

            {open ? (
                <GenerateCodeDailog handleOnClose={handleOnClose} open={open} />
            ) : null}

            <Box
                display="flex"
                flexWrap="wrap"
                height="200px"
                overflow="auto"
            >
                {
                    row.map((v) => {
                        return <Box
                            key={v.id}
                            display="none">
                            <QRCode
                                id={v.code ?? ""}
                                value={v.code ?? ""}
                                size={200}
                                level={"H"}
                                includeMargin={true}/>

                        </Box>
                    })
                }
            </Box>
        </Box>
    )
}

export default GenerateQrCode
