// ===========================|| DASHBOARD - TOTAL GROWTH BAR CHART ||=========================== //

const chartData = (series)=>{
    return {
        options: {
            chart: {
                id: 'POD'
            },
            xaxis: {
                categories: ["TotalGenerateBarcode","ScratchedQrCode","Gift/Amount Req.","Paid Gift/Amount","TotalUser","TotalActivateUser", "TotalUserRequest",  ]
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
        },
        series: [
            {
                name: '',
                data: series
            }
        ]
    }
};
export default chartData;
