import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";
import React, { useState } from "react";
import ToastView, { ToastShow } from "../Toast/Toast";
import Compressor from 'compressorjs';
import { PhotoCamera } from "@mui/icons-material";
import { API_BANNER_ADD } from "../../Apis/banner_api";



function AddBanner({ open, handleOnClose }) {

    const [selectedImage, setSelectedImage] = React.useState(null);
    const [selectedImageCover, setSelectedImageCover] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const handleOnSubmit = async () => {

        if (!selectedImage) {
            ToastShow("Please select image");
        } else {
            setIsLoading(true);
            const d = await API_BANNER_ADD(
                {
                    photo: selectedImage,
                    thumb: selectedImageCover
                }
            );
            setIsLoading(false);
            if (d && d.s) {
                ToastShow("Successfully added");
                handleOnClose(1);
            } else {
                ToastShow("Something went wrong. Please try again.");
            }
        }
    };

    return (
        <>
            <Dialog open={open} onClose={() => handleOnClose(0)}>
                <DialogTitle>Add Banner</DialogTitle>
                <DialogContent
                    sx={{
                        width: "300px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >

                    {selectedImage && (

                        <Avatar
                            sx={{
                                height: "150px",
                                width: "150px"
                            }}
                            alt="Remy Sharp" src={URL.createObjectURL(selectedImage)} />
                    )}

                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label">
                        <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(event) => {
                                if (event.target.files) {
                                    new Compressor(event.target.files[0], {
                                        quality: 0.8,
                                        success: (compressedResult) => {
                                            setSelectedImage(compressedResult);

                                        },
                                    });
                                    new Compressor(event.target.files[0], {
                                        quality: 0.5,
                                        success: (compressedResult) => {

                                            setSelectedImageCover(compressedResult);
                                        },
                                    });
                                }

                            }}
                        />
                        <PhotoCamera />
                    </IconButton>


                </DialogContent>

                <DialogActions>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <Button onClick={handleOnSubmit} variant="text">
                            Submit
                        </Button>
                    )}
                    <Button onClick={() => handleOnClose(0)} variant="text">
                        Cancel
                    </Button>
                </DialogActions>

            </Dialog>
            <ToastView />
        </>
    );
}

export default AddBanner;