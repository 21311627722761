import { API_URL, GETAPI, POSTAPI } from "./config";

export const API_GET_LIST = async (data) => {
  const res = await GETAPI(API_URL.userList,"&count="+data);
  return res;
};

export const API_USER_REQUEST = async (data) => {
    const res = await GETAPI(API_URL.userRequest,"&count="+data);
    return res;
  };

export const API_CHANGE_STATUS = async (id,status) => {
    const formData = new FormData();
    formData.append("id",id);
    formData.append("status",status);
    const res = await POSTAPI(API_URL.userUpdate,formData);
    return res;
};