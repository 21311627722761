import { Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { API_SEND_NOTIFICATION } from '../../../Apis/notification_api';
import ToastView, { ToastShow } from '../../../Component/Toast/Toast';

function Notification() {

    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");
    const [loading, setLoading] = useState(false);


    const sendNotification = async () => {

        if (!title) {
            ToastShow("Please fill title");
        } else if (!body) {
            ToastShow("Please fill body");
        } else {

            setLoading(true);
            const d = await API_SEND_NOTIFICATION(title, body);

            if (d && d.s) {
                setTitle("");
                setBody("");
                ToastShow("Success");
            } else {
                ToastShow("Opps! something went wrong. Please try again.");
            }

            setLoading(false);

        }



    }


    return (
        <Box
            sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Paper
                sx={{
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                    width: "400px"

                }}
            >
                <Typography variant='subtitle2'>Send Notification</Typography>
                <TextField sx={{ mt: 2 }} value={title} label="Title" onChange={(e) => setTitle(e.target.value)} />
                <TextField multiline
                    rows={3} sx={{ my: 2 }} value={body} label="Body" onChange={(e) => setBody(e.target.value)} />
                {loading ? <CircularProgress /> : <Button onClick={sendNotification} variant='outlined'>Send</Button>}
            </Paper>

            <ToastView />
        </Box>
    )
}

export default Notification
