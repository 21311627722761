import { ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { theme } from "./Utils/theme";
import { Routes, Route } from "react-router-dom";
import LoginAuth from "./Pages/Auth/LoginAuth";
import { Box } from "@mui/system";
import { BrowserRouter } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Dashboard from "./Pages/Screens/Dashboard/Dashboard";
import AuthContext from "./Context/AuthContext";
import AuthService from "./Service/AuthService";
import UserList from "./Pages/Screens/User/UserList";
import UserRequest from "./Pages/Screens/User/UserRequest";
import QrCode from "./Pages/Screens/QrCode/QrCode";
import QrCodeRequest from "./Pages/Screens/QrCode/QrCodeRequest";
import QrCodeComplated from "./Pages/Screens/QrCode/ComplatedRequested";
import Banner from "./Pages/Screens/Banner/Banner";
import TopRanker from "./Pages/Screens/QrCode/TopRenker";
import GenerateQrCode from "./Pages/Screens/QrCode/GenerateQrCode";
import Redeem from "./Pages/Screens/Redeem/Redeem";
import Notification from "./Pages/Screens/Notification/Notification";

function App() {
  // THEME MODE
  const [mode, setMode] = useState("dark");

  // CHNAGE THEME MODE
  const changeMode = () => {
    setMode(mode === "light" ? "dark" : "light");
  };

  // MATERIAL UI SETUP
  return (
    <AuthContext>
      <ThemeProvider theme={theme(mode)}>
        <BrowserRouter>
          <Box
            sx={{
              overflow: "hidden",
              bgcolor: "background.default",
              height: "100vh",
              width: "100vw",
              overflowX: "hidden",
            }}
          >
            {/* ROUTES */}
            <Routes>
              <Route path="/" element={<LoginAuth />} />
              <Route
                path="dashboard"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="Dashboard"
                      children={<Dashboard />}
                    />
                  </AuthService>
                }
              />

              <Route
                path="user-list"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="User List"
                      children={<UserList />}
                    />
                  </AuthService>
                }
              />

              <Route
                path="user-request"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="User Request"
                      children={<UserRequest />}
                    />
                  </AuthService>
                }
              />
              <Route
                path="requested-qr-code"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="Requested QR Code"
                      children={<QrCodeRequest />}
                    />
                  </AuthService>
                }
              />

              <Route
                path="completed-qr-request"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="Completed Request"
                      children={<QrCodeComplated />}
                    />
                  </AuthService>
                }
              />

              <Route
                path="qr-code"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="QR Code"
                      children={<QrCode />}
                    />
                  </AuthService>
                }
              />
              <Route
                path="generate-qr-code"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="Generate QR Code"
                      children={<GenerateQrCode />}
                    />
                  </AuthService>
                }
              />
              <Route
                path="banner"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="Banner"
                      children={<Banner />}
                    />
                  </AuthService>
                }
              />
              <Route
                path="top-ranker"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="Top Ranker"
                      children={<TopRanker />}
                    />
                  </AuthService>
                }
              />

              <Route
                path="config"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="Config"
                      children={<Redeem />}
                    />
                  </AuthService>
                }
              />

              <Route
                path="notification"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="Notification"
                      children={<Notification />}
                    />
                  </AuthService>
                }
              />
              <Route
                path="config"
                element={
                  <AuthService>
                    {" "}
                    <Home
                      changeMode={changeMode}
                      title="Config"
                      children={<Redeem />}
                    />
                  </AuthService>
                }
              />
            </Routes>
          </Box>
        </BrowserRouter>
      </ThemeProvider>
    </AuthContext>
  );
}

export default App;
