import * as React from "react";
import { Settings, Delete, Edit, Visibility } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Fade,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

export default function SplitButton({
  dataId,
  isBlocked = 0,
  isReject = 0,
  isActivate = 0,
  onSubmit
}) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Avatar
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        sx={{
          height: "35px",
          width: "35px",
          cursor: "pointer",
        }}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        color="inherit"
      >
        <Settings />
      </Avatar>

      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ p: 2 }}>
                  {isActivate && (
                    <Button
                      variant="text"

                      onClick={() => {
                        onSubmit(dataId, 1);
                        handleClose();
                      }}
                    >Activate</Button>
                  )}
                  {isBlocked && (
                    <Button
                      variant="text"

                      onClick={() => {
                        onSubmit(dataId, -1);
                        handleClose();



                      }}
                    >Block</Button>
                  )}
                  {isReject && (
                    <Button
                      variant="text"

                      onClick={() => {
                        onSubmit(dataId, 2);
                        handleClose();
                      }}
                    >Reject</Button>
                  )}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

function ListItemButtonView({ icon, text, onClick }) {
  return (
    <ListItemButton
      sx={{ borderRadius: "10px", p: "0px", py: "3px", px: "1px" }}
      onClick={onClick}
    >
      <ListItemIcon sx={{ p: "0px" }}>{icon}</ListItemIcon>
      <ListItemText
        sx={{ p: "0px" }}
        primary={<Typography variant="body2">{text}</Typography>}
      />
    </ListItemButton>
  );
}
