import { Avatar, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Apis/config";
import { API_QR_REQUEST_COMPLATED } from "../../../Apis/qr_code_api";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";

function QrCodeComplated() {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCall, setIsCall] = useState(true);


  const getData = async () => {
    setIsLoading(true);

    const data = await API_QR_REQUEST_COMPLATED(rows.length);

    if (data && data.s) {
            setRows([...rows, ...(data.r ?? [])]);
    }

    setIsLoading(false);
    if ((data.r ?? []).length == 0) {
      setIsCall(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const callBack = (l) => {
    if (l >= rows.length && isCall) {
      getData();
    }
  };



  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
      width: 50,
    },
    {
      field: "profile",
      headerAlign: "center",
      align: "center",
      headerName: "Profile",
      width: 70,

      renderCell: (params) => {
        return (
          <Avatar
            src={`${API_URL.baseUrl + API_URL.userProfile + params.value}`}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      width: 150,
      type: "string",
    },
    {
      field: "m_no",
      headerName: "Mobile No.",
      headerAlign: "center",
      align: "center",
      width: 130,
      type: "string",
    },

    {
      field: "t_point",
      headerName: "Total Point",
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params) => {
        return <Typography>{params.row.points.t_point}</Typography>;
      },
    },
    {
      field: "upi_id",
      headerAlign: "center",
      align: "center",
      headerName: "UPI-ID",
      width: 190,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.upi_id}</Typography>;
      },
    },
    {
      field: "bank_name",
      headerAlign: "center",
      align: "center",
      headerName: "Bank Name",
      width: 190,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.bank_name}</Typography>;
      },
    },
    {
      field: "ifsc",
      headerAlign: "center",
      align: "center",
      headerName: "IFSC",
      width: 150,
      renderCell: (params) => {
        return <Typography>{params.row.bank_details.ifsc}</Typography>;
      },
    },
    {
      field: "ac",
      headerAlign: "center",
      align: "center",
      headerName: "AC.NO.",
      width: 190,
      renderCell: (params) => {
        return (
          <Box>
            <Typography>{params.row.bank_details.ac_num}</Typography>
          </Box>
        );
      },
    },
    {
      field: "req_type",
      headerAlign: "center",
      align: "center",
      headerName: "Req. Type",
      width: 190,
      renderCell: (params) => {
        return (
          <Box>
            <Typography>{params.value == 1 ? "Money":"Reward"}</Typography>
          </Box>
        );
      },
    },
    {
      field: "amount",
      headerAlign: "center",
      align: "center",
      headerName: "Amount/Exp.",
      width: 190,
      renderCell: (params) => {
        return (
          <Box>
            <Typography>₹{params.value}</Typography>
          </Box>
        );
      },
    },

    {
      field: "paid_date_time",
      headerAlign: "center",
      align: "center",
      headerName: "Paid Date-Time",
      width: 190,
      renderCell: (params) => {
        return (
          <Box>
            <Typography>{params.value}</Typography>
          </Box>
        );
      },
    },
    {
      field: "paid_document_id",
      headerAlign: "center",
      align: "center",
      headerName: "Paid Ref. ID",
      width: 190,
      renderCell: (params) => {
        return (
          <Box>
            <Typography>{params.value}</Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <DataGrideView
        columns={columns}
        rows={rows}
        callBack={callBack}
        isLoading={isLoading}
      />

    </>
  );
}

export default QrCodeComplated;
