
import React, { useState } from "react";
import QRCode from "qrcode.react";
import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { API_QR_GENERATED_LIST, GENERATE_QR_CODE } from "../../../Apis/qr_code_api";
import AskToGenerateCode from "../../../Component/Dailog/AskToGenerateCode";
import DataGrideView from "../../../Component/DataGrideView/DataGrideView";

function QrCode() {
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCall, setIsCall] = useState(true);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);


    async function getData() {
        const v = await GENERATE_QR_CODE();
        if (v && v.s) {
            setData(v.r);
        }
    }

    const openDialog = () => {
        setOpen(true);
    };

    const handleOnClose = async (v) => {
        setOpen(false);
        if (v) {
            getData();
        }

    };

    const getDataCode = async (v = 1) => {
        setIsLoading(true);

        const data = await API_QR_GENERATED_LIST(v ? rows.length : 0);

        if (v) {
            if (data && data.s) {
                setRows([...rows, ...(data.r ?? [])]);
            }
        } else {
            setRows([...(data.r ?? [])]);
        }

        setIsLoading(false);

        if ((data.r ?? []).length == 0) {
            setIsCall(false);
        }
    };

    const callBack = (l) => {
        if (l >= rows.length && isCall) {
            getDataCode();
        }
    };

    useEffect(() => {
        if (data.length) {
            setTimeout(() => {
                for (const d of data) {

                    downloadQRCode(d.id);
                }
                setData([]);
            }, 500);
            clearTimeout();
        }
        setIsCall(true);
        getDataCode(0)
    }, [data]);


    const downloadQRCode = (code) => {
        // Generate download with use canvas and stream
        const canvas = document.getElementById(code);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `silguard_${code.split("/")[code.split("/").length - 1]}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const columns = [
        {
            field: "id",
            headerName: "ID",
            headerAlign: "center",
            align: "center",
            width: 100,
        },
        {
            field: "point",
            headerName: "Point",
            headerAlign: "center",
            align: "center",
            width: 100,
        },
        {
            field: "code",
            headerName: "Code",
            headerAlign: "center",
            align: "center",
            width: 300,
        },
        {
            field: "ltr",
            headerName: "Ltr",
            headerAlign: "center",
            align: "center",
            width: 100,
        },
        {
            field: "used",
            headerName: "Status",
            headerAlign: "center",
            align: "center",
            width: 100,
            renderCell: (params) => {
                return params.value != 0 ? <Typography sx={{ color: "red" }}>Used</Typography> : <Typography >Not used</Typography>
            }
        },
        {
            field: "codes",
            headerName: "Qr-Code Download",
            headerAlign: "center",
            align: "center",
            width: 200,
            renderCell: (params) => {
                return <Box
                    display="flex"
                >
                    <QRCode
                        id={params.row.code ?? ""}
                        value={params.row.code ?? ""}
                        size={50}
                        level={"H"}
                        includeMargin={true}
                    />
                    <Button
                        onClick={() => downloadQRCode(params.row.code)}
                        size="small" variant="outlined" sx={{ ml: 1 }}>Download</Button>
                </Box>
            }
        },


    ];

    return (
        <Box display="flex" alignItems="end" flexDirection="column">


            <DataGrideView
                columns={columns}
                rows={rows}
                callBack={callBack}
                isLoading={isLoading}
            />

            {
                open ? <AskToGenerateCode open={open} handleOnClose={handleOnClose} /> : null
            }
        </Box>
    );

}

export default QrCode



